<template>
  <TransitionRoot :model-value="modelValue" :show="modelValue" as="template">
    <Dialog class="relative z-50" @close="close()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute right-0 top-0 pr-4 pt-4 block">
                <button
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    type="button"
                    @click="close()">
                  <span class="sr-only">Close</span>
                  <svg aria-hidden="true" class="h-6 w-6" fill="none" stroke="currentColor" stroke-width="1.5"
                       viewBox="0 0 24 24">
                    <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-electric-violet-100">
                  <svg aria-hidden="true" class="h-6 w-6 text-electric-violet-600" data-slot="icon" fill="none"
                       stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900 text-center">
                    Talk to us
                  </DialogTitle>
                  <div class="mt-2">

                    <p class="text-sm mb-4">We are here to help you with any questions you may have. You can chat with us on WhatsApp, leave us a message, or book a call.</p>

                    <div v-if="page === 0" class="flex flex-col gap-6">
                      <section>
                        <p class="mb-3">Chat with us on WhatsApp</p>
                        <a class="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-electric-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-electric-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-violet-600"
                           href="https://wa.me/+4402034759330" rel="noopener noreferrer" target="_blank"
                           @click.prevent="openWhatsApp()">
                          <svg class="-ml-0.5 h-5 w-5" fill="currentColor" viewBox="0 0 20 20"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m10 0c2.7 0 5.2 1 7 2.9 2 1.9 3 4.4 3 7 0 5.4-4.4 9.8-9.8 9.9h-.2c-1.6 0-3.3-.4-4.7-1.2h-.1l-5.2 1.4 1.4-5.1c-.9-1.5-1.3-3.2-1.3-5 0-5.5 4.5-9.9 9.9-9.9zm0 1.7c-4.6 0-8.3 3.7-8.3 8.2 0 1.5.4 3 1.2 4.3v.1l.2.3-.8 3 3.1-.8.4.2c1.3.7 2.7 1.1 4.1 1.1h.1c4.6 0 8.3-3.7 8.3-8.2 0-2.2-.9-4.3-2.4-5.8-1.6-1.6-3.6-2.4-5.9-2.4zm-3.5 3.6h.5c.2 0 .4-.1.6.4 0 .3.1.5.3.8v.1c.2.5.4.9.4 1 .1.1.1.3 0 .4 0 .2-.1.3-.2.5-.1.1-.3.3-.4.4-.1.1-.2.3-.1.5s.6 1.1 1.4 1.7c1 .8 1.8 1.1 2 1.2s.4.1.5-.1.6-.7.8-1c.2-.2.3-.2.6-.1.1 0 .4.2.8.4l.1.1c.3.2.7.3.8.4.2.1.4.2.5.3s.1.6-.1 1.2-1.2 1.1-1.7 1.2c-.4.1-1 .1-1.6-.1-.4-.1-.8-.3-1.4-.5-2.5-1.1-4.1-3.6-4.3-3.7-.2-.2-1-1.4-1-2.6s.6-1.8.9-2.1c.2-.3.5-.4.6-.4z"
                                fill="currentColor"/>
                          </svg>
                          Start chat on WhatsApp
                        </a>
                      </section>

                      <section>
                        <p class="mb-3">Leave us a message</p>
                        <button
                            class="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-electric-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-electric-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-violet-600"
                            @click.prevent="leaveMessage()">
                          <svg aria-hidden="true" class="-ml-0.5 h-5 w-5" data-slot="icon" fill="none"
                               stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          Leave us a message
                        </button>
                      </section>

                      <section>
                        <p class="mb-3">Book a call</p>
                        <a class="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-electric-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-electric-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-violet-600"
                           href="https://cal.com/samos/simon-perkins"
                           rel="noopener noreferrer" target="_blank" @click.prevent="bookCall()"
                        >
                          <svg aria-hidden="true" class="-ml-0.5 h-5 w-5" data-slot="icon" fill="none"
                               stroke="currentColor"
                               stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          Book a call now
                        </a>
                      </section>
                    </div>

                    <section v-if="page === 1">
                      <h3 class="mb-3 flex gap-1">
                        <button
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            type="button"
                            @click="back()">
                          <span class="sr-only">Back</span>
                          <svg aria-hidden="true" class="h-6 w-6" data-slot="icon" fill="none" stroke="currentColor"
                               stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </button>
                        Leave us a message
                      </h3>
                      <teleport to="head">
                        <component :is="'script'" defer src="https://webforms.pipedrive.com/f/loader"></component>
                      </teleport>
                      <div class="pipedriveWebForms"
                           data-pd-webforms="https://webforms.pipedrive.com/f/c6vTfGZlkZBGhnu8TmiBalknRfZ15dfRq3UFeTzRoQA9cc0tXjANW67CDoW8ENAnWr"></div>
                    </section>

                  </div>
                </div>
              </div>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ref} from "vue";

const props = defineProps({modelValue: Boolean});
const emit = defineEmits(['update:modelValue']);
const page = ref(0);

function close() {
  emit('update:modelValue', false);
}

function back() {
  page.value = 0;
}

function openWhatsApp() {
  window.open('https://wa.me/+4402034759330', '_blank');
  gtag('event', 'click', {
    'event_category': 'Contact Us Dialogue',
    'event_label': 'WhatsApp'
  });
}

function leaveMessage() {
  page.value = 1;
  gtag('event', 'click', {
    'event_category': 'Contact Us Dialogue',
    'event_label': 'Leave Message'
  });
}

function bookCall() {
  window.open('https://cal.com/samos/simon-perkins', '_blank');
  gtag('event', 'click', {
    'event_category': 'Contact Us Dialogue',
    'event_label': 'Book Call'
  });
}

</script>